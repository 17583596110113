#okta-sign-in {
  margin: 100px auto 8px;
}

#okta-sign-in .okta-sign-in-header.auth-header {
  padding-bottom: 15px;
  padding-top: 15px;
}

#okta-sign-in .auth-org-logo {
  max-height: 54px;
  max-width: 200px;
}

#okta-sign-in {
  font-family: "archivo-regular";
}

#okta-sign-in .button.button-primary {
  background: #ffce00;
  border-color: #ffce00;
  color: black;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: none;
}

#okta-sign-in .button.button-primary:hover,
#okta-sign-in .button.button-primary:active,
#okta-sign-in .button.button-primary:focus,
#okta-sign-in .button.button-primary:visited {
  background: #ffce00;
  border-color: #ffce00;
  height: 44px;
  box-shadow: none;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  background: #ffce00;
  border-color: #ffce00;
  color: #000000;
}

#okta-sign-in.auth-container .o-form-head::before {
  content: "myAccount is now accessed \A from myYellow there is no change \A to your sign in details \A\A";
  color: #5e5e5e;
  white-space: pre;
}

#okta-sign-in .o-form .input-fix {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
}

@media screen and (min-width: 768px) {
  #okta-sign-in.auth-container.main-container {
    border: none;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

#okta-sign-in.auth-container .okta-form-input-field input {
  font-family: "Archivo";
}

#okta-sign-in.auth-container input[type="submit"], #okta-sign-in.auth-container input[type="button"] {
  font-family: "Archivo";
}

#okta-sign-in.auth-container.main-container {
  font-family: "Archivo" !important;
}